import styled from 'styled-components';
import { StyledButton } from '../Button/styles';
import { px2vw } from '../../utils/px2vw';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 20px;
  padding-right: 40px;

  @media only screen and (max-width: 600px) {
    padding-right: 20px;
  }
`;

export const Logo = styled.img`
  width: 200px;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    width: 150px;
  }
`;

export const ButtonContainer = styled.a`
  font-size: 16px;
  text-decoration: none;
  margin-right: 20px;

  @media only screen and (max-width: 600px) {
    width: 100px;
    font-size: 14px;
    height: 37px;
    margin-top: -6px;
    padding: 7px;
  }
`;

export const Button = styled(StyledButton)`
  width: ${props => (props.isLink ? props.width : '100px')};
  font-size: 16px;
  padding: 10px;

  @media only screen and (max-width: 600px) {
    width: 100px;
    font-size: 14px;
    height: 37px;
    margin-top: -6px;
    padding: 7px;
  }
`;

export const AvatarMenu = styled.div`
  cursor: pointer;
  position: relative;

  img {
    width: ${px2vw(50)};
    height: ${px2vw(50)};
    border-radius: 50%;
    margin-right: ${px2vw(20)};
    border: 1px solid #e0e0e0;
    @media only screen and (max-width: 600px) {
      width: 50px;
      height: 50px;
    }
  }
`;

export const AvatarMenuItems = styled.ul`
  visibility: hidden;
  transition: all 0.2s;
  transform: scale(0.8) translate(20px, -30px);
  opacity: 0;

  position: absolute;
  z-index: 3;
  right: 0;
  top: calc(100% + 5px);
  padding: 0;
  margin: 0;
  background: white;
  line-height: 45px;
  padding: 8px 0;
  min-width: 180px;
  border-radius: 3px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  @media only screen and (max-width: 600px) {
    font-size: 15px;
  }

  a {
    text-decoration: none;
  }

  li {
    padding: 0 1rem;
    display: block;
    color: #555;
    &:hover {
      background: #fafafa;
    }

    @media only screen and (max-width: 600px) {
      padding: 10px;
    }
  }

  ${({ isOpened }) =>
    isOpened &&
    `
    	display: block;
	    visibility: visible;
	    opacity: 1;
	    transform: scale(1) translate(0, 0);
  `}
`;
