import styled from 'styled-components';
import { px2vw } from '../../utils/px2vw';
import { shade } from 'polished';
import { StyledButton } from '../../components/Button/styles';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #050050;

  @media only screen and (max-width: 600px) {
    height: 100%;
    align-items: flex-start;
    overflow-x: hidden;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${px2vw(540)};
  height: ${px2vw(880)};
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: ${px2vw(48)} 0;
  margin-bottom: 50px;
  margin-top: 50px;

  @media only screen and (max-width: 600px) {
    width: 90%;
    height: 900px;
    font-size: 14px;
    margin-top: 40px;
  }

  h2 {
    color: #050050;
    font-size: 30px;
  }

  img {
    width: ${px2vw(232)};
    height: ${px2vw(110)};

    @media only screen and (max-width: 600px) {
      width: 100%;
      height: fit-content;
    }
  }

  form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 600px) {
      width: 80%;
    }

    a {
      margin-top: 4px;
      @media only screen and (max-width: 600px) {
        text-align: center;
      }
    }

    button {
      flex: 1;
      margin-top: 22px;
      margin-right: 0;

      &:hover {
        background-color: ${shade(0.2, '#3D00C8')};
      }
    }

    button + button {
      margin-top: 8px;
    }
  }

  & > button {
    margin-top: 8px;
  }

  & > div {
    display: flex;
    margin-top: ${px2vw(30)};
  }
`;

export const Input = styled.input`
  position: relative;
  width: ${px2vw(338)};
  height: ${px2vw(48)};
  border: 1px solid #3d00c8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 ${px2vw(16)};
  font-size: 1rem;
  margin-bottom: 10px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 45px;
    font-size: 14px;
    padding: 10px;
  }

  &:focus {
    border: 2px solid #3d00c8;
  }
`;

export const Button = styled(StyledButton)`
  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 14px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserTypeCheckbox = styled.input`
  opacity: 0;
  position: absolute;
  display: none;

  & + label:before {
    content: '';
    background: #fff;
    border-radius: 5px;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;

    @media only screen and (max-width: 600px) {
      width: 25px;
      height: 25px;
      vertical-align: text-top;
    }
  }

  &:checked + label:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 11px;
    border: solid #050050;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    border-radius: 0px;
    margin: 0px 15px 5px 10px;

    @media only screen and (max-width: 600px) {
      height: 20px;
      margin: 0px 15px 5px 20px;
      height: 21px;
    }
  }
`;

export const UserTypeLabel = styled.label`
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  position: relative;

  > span {
    font-size: 15px;
    color: #999999;

    @media only screen and (max-width: 600px) {
      margin-left: 43px;
      position: absolute;
      top: 28px;
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 600px) {
    line-height: 1px;
    margin-bottom: 20px;
  }
`;
