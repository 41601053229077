import React, { useState, createRef, useEffect } from 'react';

import {
  Container,
  ProfileImageContainer,
  Box,
  FileUpload,
  Input,
  Button,
  ButtonsContainer,
  SelectInput,
  Title,
} from './styles';
import Header from '../../components/Header';
import { useAuth } from '../../context/auth';
import { Redirect, Link } from '@reach/router';
import NoAvatar from '../../assets/images/noAvatar.png';
import ErrorHolder from '../../components/ErrorHolder';
import { Spin } from '../../components/FullPageSpinner/styles';
import SuccessHolder from '../../components/SuccessHolder';

const instituitionTypes = [
  { name: 'ONG', value: 'ong' },
  { name: 'Lar Temporário', value: 'larTemporario' },
  { name: 'Abrigo', value: 'abrigo' },
  { name: 'Centro de Zoonozes', value: 'centroZoonoses' },
  { name: 'Clínica Veterinária', value: "'clinicaVeterinaria" },
  { name: 'Laboratório', value: 'laboratorio' },
  { name: 'Pet Shop', value: 'petShop' },
  { name: 'Profissional Autônomo', value: 'profissionalAutonomo' },
  { name: 'Feira de Adoção', value: 'feiraAdocao' },
  { name: 'Outros', value: 'outros' },
];

function InstitutionProfile() {
  const fileInputRef = createRef();
  const {
    isLoggedIn,
    data,
    updateInstitutionAvatar,
    updateInstitutionInfo,
    refreshPage,
  } = useAuth();
  const [selectedAvatar, setSelectedAvatar] = useState({
    file: '',
    preview: '',
  });
  const [form, setForm] = useState({
    name: '',
    phone: '',
    email: '',
    type: '',
    street: '',
    neighborhood: '',
    number: '',
    city: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);
  const [hasTouchedEdit, setHasTouchedEdit] = useState(false);

  useEffect(() => {
    setForm({
      name: data && data.institutions.data[0] && data.institutions.data[0].nome,
      phone:
        data && data.institutions.data[0] && data.institutions.data[0].celular,
      email:
        data && data.institutions.data[0] && data.institutions.data[0].email,
      type: data && data.institutions.data[0] && data.institutions.data[0].tipo,
      street:
        data &&
        data.institutions.data[0] &&
        data.institutions.data[0].logradouro,
      neighborhood:
        data && data.institutions.data[0] && data.institutions.data[0].bairro,
      number:
        data && data.institutions.data[0] && data.institutions.data[0].numero,
      city:
        data && data.institutions.data[0] && data.institutions.data[0].cidade,
    });
  }, []);

  const onChangeInput = e => {
    setHasTouchedEdit(true);
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setForm({ ...form, [name]: value });
  };

  const onChangeAvatarImage = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setSelectedAvatar({
        file: file,
        preview: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  const onTryUploadImage = async () => {
    let res = { message: '' };
    if (selectedAvatar.preview.length !== 0) {
      res = await updateInstitutionAvatar(
        selectedAvatar.file,
        data.institutions.data[0]._id
      );
    }

    return res;
  };
  const onTryUpdateInstitutionInfo = async () => {
    const res = await updateInstitutionInfo({
      id: data.institutions.data[0]._id,
      usuario: data.institutions.data[0].usuario,
      estado: data.institutions.data[0].estado,
      complemento: data.institutions.data[0].complemento,
      capa: data.institutions.data[0].capa,
      logo:
        data.institutions.data[0].logo !== null &&
        data.institutions.data[0].logo,
      ...form,
    });
    return res;
  };

  const onSaveChanges = async e => {
    e.preventDefault();
    setLoading(true);
    setIsEditing(false);
    Promise.all([onTryUploadImage(), onTryUpdateInstitutionInfo()])
      .then(res => {
        setSuccess(hasTouchedEdit ? res : [res[0]]);
        setLoading(false);
        refreshPage();
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  const onEditClicked = () => {
    setIsEditing(true);
    setHasTouchedEdit(true);
  };

  return (
    <>
      <Header />
      <Container>
        <Box>
          <Title>Minha instituição</Title>
          <ProfileImageContainer>
            <img
              alt="Imagem de perfil"
              src={
                data &&
                data.institutions &&
                data.institutions.data[0] &&
                data.institutions.data[0].logo !== null
                  ? selectedAvatar.preview.length !== 0
                    ? selectedAvatar.preview
                    : data.institutions.data[0].logo
                  : selectedAvatar.preview.length !== 0
                  ? selectedAvatar.preview
                  : NoAvatar
              }
            />
          </ProfileImageContainer>
          <FileUpload onClick={() => fileInputRef.current.click()}>
            <input
              accept="image/*"
              ref={fileInputRef}
              onChange={onChangeAvatarImage}
              type="file"
            />
            <span>Alterar imagem de perfil</span>
          </FileUpload>
          <Input
            required
            disabled={!isEditing}
            onChange={onChangeInput}
            value={form.name}
            name="name"
            type="text"
            placeholder="Nome"
          />
          <Input
            required
            disabled={!isEditing}
            onChange={onChangeInput}
            value={form.phone}
            name="phone"
            type="text"
            placeholder="Telefone"
          />
          <SelectInput
            disabled={!isEditing}
            defaultValue={form.type}
            name="type"
            onChange={onChangeInput}
            value={form.type}
          >
            {instituitionTypes.map(type => (
              <option key={type.value} value={type.value}>
                {type.name}
              </option>
            ))}
          </SelectInput>
          <Input
            required
            disabled={!isEditing}
            onChange={onChangeInput}
            value={form.street}
            name="street"
            type="text"
            placeholder="Rua"
          />
          <Input
            required
            disabled={!isEditing}
            onChange={onChangeInput}
            value={form.neighborhood}
            name="neighborhood"
            type="text"
            placeholder="Bairro"
          />
          <Input
            required
            disabled={!isEditing}
            onChange={onChangeInput}
            value={form.number}
            name="number"
            type="text"
            placeholder="Número"
          />
          <Input
            required
            disabled={!isEditing}
            onChange={onChangeInput}
            value={form.city}
            name="city"
            type="text"
            placeholder="Cidade"
          />
          <Input
            required
            disabled
            onChange={onChangeInput}
            value={form.email}
            name="street"
            type="text"
            placeholder="Email"
          />
          <ButtonsContainer>
            <Button onClick={onEditClicked} isOutline>
              Editar
            </Button>
            <Button
              onClick={onSaveChanges}
              disabled={selectedAvatar.preview.length === 0 && !isEditing}
            >
              Salvar alterações
            </Button>
          </ButtonsContainer>
          {loading && <Spin />}
          {error.length !== 0 && (
            <ErrorHolder
              style={{ textAlign: 'center', marginTop: '10px' }}
              errors={error ? error : []}
            />
          )}

          {success.length !== 0 && (
            <>
              {success.map(s => (
                <SuccessHolder message={s.message} />
              ))}
              <Link to="/">
                <div style={{ fontSize: '17px' }}>
                  Voltar para página principal
                </div>
              </Link>
            </>
          )}
        </Box>
      </Container>
      {!isLoggedIn && <Redirect to="/" noThrow />}
    </>
  );
}

export default InstitutionProfile;
