import React, { useState, createRef, useEffect } from 'react';

import {
  Container,
  ProfileImageContainer,
  Box,
  FileUpload,
  Input,
  Button,
  ButtonsContainer,
  Title,
} from './styles';
import Header from '../../components/Header';
import { useAuth } from '../../context/auth';
import { Redirect, Link } from '@reach/router';
import NoAvatar from '../../assets/images/noAvatar.png';
import ErrorHolder from '../../components/ErrorHolder';
import { Spin } from '../../components/FullPageSpinner/styles';
import SuccessHolder from '../../components/SuccessHolder';

function Profile() {
  const fileInputRef = createRef();
  const {
    isLoggedIn,
    data,
    updateUserAvatar,
    updateUserInfo,
    refreshPage,
  } = useAuth();
  const [selectedAvatar, setSelectedAvatar] = useState({
    file: '',
    preview: '',
  });
  const [form, setForm] = useState({
    name: '',
    phone: '',
    email: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);
  const [hasTouchedEdit, setHasTouchedEdit] = useState(false);

  useEffect(() => {
    setForm({
      name: data && data.user && data.user.data && data.user.data.nome,
      phone: data && data.user && data.user.data && data.user.data.telefone,
      email: data && data.user && data.user.data && data.user.data.email,
    });
  }, []);

  const onChangeInput = e => {
    setHasTouchedEdit(true);
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setForm({ ...form, [name]: value });
  };

  const onChangeAvatarImage = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setSelectedAvatar({
        file: file,
        preview: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  const onTryUploadImage = async () => {
    let res = { message: '' };
    if (selectedAvatar.preview.length !== 0) {
      res = await updateUserAvatar(selectedAvatar.file, data.user.data._id);
    }

    return res;
  };

  const onTryUpdateUserInfo = async () => {
    const res = await updateUserInfo({ id: data.user.data._id, ...form });
    return res;
  };

  const onSaveChanges = async e => {
    e.preventDefault();
    setLoading(true);
    Promise.all([onTryUploadImage(), onTryUpdateUserInfo()])
      .then(res => {
        setSuccess(hasTouchedEdit ? res : [res[0]]);
        setLoading(false);
        refreshPage();
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  const onEditClicked = () => {
    setIsEditing(true);
    setHasTouchedEdit(true);
  };

  return (
    <>
      <Header />
      <Container>
        <Box>
          <Title>Meu perfil</Title>
          <ProfileImageContainer>
            <img
              alt="Imagem de perfil"
              src={
                data &&
                data.user &&
                data.user.data &&
                data.user.data.avatar !== null
                  ? selectedAvatar.preview.length !== 0
                    ? selectedAvatar.preview
                    : data.user.data.avatar
                  : selectedAvatar.preview.length !== 0
                  ? selectedAvatar.preview
                  : NoAvatar
              }
            />
          </ProfileImageContainer>
          <FileUpload onClick={() => fileInputRef.current.click()}>
            <input
              accept="image/*"
              ref={fileInputRef}
              onChange={onChangeAvatarImage}
              type="file"
            />
            <span>Alterar imagem de perfil</span>
          </FileUpload>
          <Input
            required
            disabled={!isEditing}
            onChange={onChangeInput}
            value={form.name}
            name="name"
            type="text"
            placeholder="Nome"
          />
          <Input
            required
            disabled={!isEditing}
            onChange={onChangeInput}
            value={form.phone}
            name="phone"
            type="text"
            placeholder="Telefone"
          />
          <Input
            required
            disabled
            onChange={onChangeInput}
            value={form.email}
            name="street"
            type="text"
            placeholder="Email"
          />
          <ButtonsContainer>
            <Button onClick={onEditClicked} isOutline>
              Editar
            </Button>
            <Button
              onClick={onSaveChanges}
              disabled={selectedAvatar.preview.length === 0 && !isEditing}
            >
              Salvar alterações
            </Button>
          </ButtonsContainer>
          {loading && <Spin />}
          {error.length !== 0 && (
            <ErrorHolder
              style={{ textAlign: 'center', marginTop: '10px' }}
              errors={error ? error : []}
            />
          )}

          {success.length !== 0 && (
            <>
              {success.map(s => (
                <SuccessHolder message={s.message} />
              ))}
              <Link to="/">
                <div style={{ fontSize: '17px' }}>
                  Voltar para página principal
                </div>
              </Link>
            </>
          )}
        </Box>
      </Container>
      {!isLoggedIn && <Redirect to="/" noThrow />}
    </>
  );
}

export default Profile;
