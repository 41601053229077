import styled from 'styled-components';
import { px2vw } from '../../utils/px2vw';
import { StyledButton } from '../../components/Button/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Title = styled.h1`
  width: 100%;
  font-family: 'Circular Std';
  font-size: 2.275rem;
  text-align: center;
  color: #050050;
  span {
    color: #00ee96;
  }

  @media only screen and (max-width: 600px) {
    font-size: 30px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${px2vw(540)};
  height: ${px2vw(660)};
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: ${px2vw(48)} 0;
  margin-bottom: 50px;
  margin-top: 50px;

  @media only screen and (max-width: 600px) {
    width: 90%;
    height: 660px;
    font-size: 13px;
    margin-top: 40px;
    padding-top: 30px;
  }
`;

export const ProfileImageContainer = styled.div`
  display: flex;

  img {
    width: ${px2vw(130)};
    height: ${px2vw(130)};
    border-radius: 50%;
    margin-right: ${px2vw(20)};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
      margin-top: 20px;
      width: 110px;
      height: 110px;
    }
  }
`;

export const FileUpload = styled.form`
  background: #4bd1a0;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  color: #fff;
  font-size: ${px2vw(15)};
  font-weight: bold;
  margin: 1.25em auto;
  overflow: hidden;
  padding: 10px 2px;
  position: relative;
  text-align: center;
  width: ${px2vw(220)};
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
    height: 40px;
    width: 180px;
    padding: 10px 5px;
  }

  &:hover,
  &:active,
  &:focus {
    background: #46bf93;
    cursor: pointer;
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 148px;
    height: 46px;
    cursor: pointer;
    position: fixed;
    right: 100%;
    bottom: 100%;
  }

  > span {
    padding-left: 2px;
  }
`;

export const Input = styled.input`
  position: relative;
  width: ${px2vw(338)};
  height: ${px2vw(48)};
  border: 1px solid #3d00c8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 ${px2vw(16)};
  font-size: 1rem;
  margin-bottom: 10px;
  margin-top: 5px;

  &:disabled {
    cursor: not-allowed;
  }

  @media only screen and (max-width: 600px) {
    width: 80%;
    height: 40px;
    font-size: 13px;
    padding: 10px;
  }

  &:focus {
    border: 2px solid #3d00c8;
  }
`;

export const Button = styled(StyledButton)`
  &:disabled {
    cursor: not-allowed;
    background-color: #a5a5a5;
  }
  margin-top: 10px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 13px;
    margin-right: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    width: 80%;
    font-size: 13px;
  }
`;
