import React from 'react';
import { Link } from '@reach/router';

import {
  Container,
  InfoBox,
  Text,
  WrapContent,
  Box,
  ImageContainer,
  Button,
  Title,
} from './styles';

import Donate from '../../assets/images/donate.png';
import Help from '../../assets/images/help.png';
import { useAuth } from '../../context/auth';

export default function Covid({ scrollToHelpBlog }) {
  const { isLoggedIn } = useAuth();
  return (
    <Container>
      {!isLoggedIn && (
        <>
          <InfoBox>COVID-19</InfoBox>
          <Text fontSize="1.375rem" marginTop={20}>
            Sabemos que um dos primeiros a sentirem os efeitos negativos
            causados pela pandemia da COVID-19 são os animais. Por outro lado,
            também sabemos que existe muita solidariedade no mundo.
          </Text>
          <Text
            fontSize="2rem"
            fontWeight="bold"
            marginTop={24}
            textAlign="center"
            as="h2"
          >
            Queremos fazer a ponte entre quem precisa de ajuda e aqueles que
            podem ajudar.
          </Text>
        </>
      )}
      {isLoggedIn && (
        <Title>
          A maneira mais <span>inteligente</span> de ajudarmos os Pets
        </Title>
      )}

      <WrapContent>
        <Box>
          <Text fontSize="1.4rem" fontWeight={400}>
            Tem algum alimento ou medicamento sobrando? Doe!
          </Text>
          <ImageContainer>
            <img src={Donate} alt="Posso ajudar" />
          </ImageContainer>
          <Button onClick={scrollToHelpBlog} width={300} bgColor="#3D00C8">
            Posso ajudar!
          </Button>
        </Box>
        <Box>
          <Text fontSize="1.4rem" fontWeight={400}>
            Está precisando de ajuda? <br /> Faça um pedido!
          </Text>
          <ImageContainer>
            <img src={Help} alt="Preciso do ajuda!" />
          </ImageContainer>
          <Link to={isLoggedIn ? '/pedir-ajuda' : '/login'}>
            <Button width={300} bgColor="#3D00C8">
              Preciso de ajuda!
            </Button>
          </Link>
        </Box>
      </WrapContent>
    </Container>
  );
}
