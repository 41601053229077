// TODO: Retrieve from backend
export const helpOptions = [
  {
    id: 1,
    name: 'racao',
    label: 'Ração',
  },
  {
    id: 2,
    name: 'medicamento',
    label: 'Medicamento',
  },
  {
    id: 3,
    name: 'dinheiro',
    label: 'Dinheiro',
  },
  {
    id: 4,
    name: 'outros',
    label: 'Outros',
  },
];
