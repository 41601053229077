import React from 'react';

import {
  Container,
  Section,
  Title,
  Text,
  Image,
  ContentWrapper,
  Button,
  ButtonContainer,
} from './styles';
import image from '../../assets/images/image.png';
import { redirectTo } from '@reach/router';

export default function Home({ scrollToHelpBlog }) {
  return (
    <Container>
      <Section>
        <ContentWrapper>
          {isMobile() ? (
            <Title>
              A maneira mais <br /> <span>inteligente</span> de <br /> ajudarmos
              os Pets
            </Title>
          ) : (
            <Title>
              A maneira mais <span>inteligente</span> de ajudarmos os Pets
            </Title>
          )}

          <Text>
            Somos uma startup que pretende ELIMINAR o abandono e sofrimento
            Animal no Brasil.
          </Text>

          <Button onClick={scrollToHelpBlog} bgColor="#3D00C8" color="#fff">
            Ajude agora mesmo
          </Button>
        </ContentWrapper>
      </Section>
      <Section>
        <Image>
          <img src={image} alt="A dog hug a human" />
        </Image>
      </Section>
    </Container>
  );
}

function isMobile() {
  return window.innerWidth <= 718;
}
