import React, { useRef, useEffect } from 'react';

import Header from '../../components/Header';
import Home from '../Home';
import Covid from '../Covid';
import HelpBlog from '../HelpBlog';
import { useAuth } from '../../context/auth';
import Footer from '../../components/Footer';
import { WhatsAppFloatingButton } from '../../components/WhatsappFloatingButton';

const scrollToRef = ref =>
  window.scrollTo({
    top: ref.current.offsetTop,
    left: 0,
    behavior: 'smooth',
  });

export default function Main({ location }) {
  const { isLoggedIn } = useAuth();
  const helpBlogRef = useRef();
  const executeScroll = () => scrollToRef(helpBlogRef);

  return (
    <>
      <Header scrollToHelpBlog={executeScroll} />
      {!isLoggedIn && <Home scrollToHelpBlog={executeScroll} />}
      <Covid scrollToHelpBlog={executeScroll} />
      <HelpBlog blogRef={helpBlogRef} />
      <WhatsAppFloatingButton />
      <Footer />
    </>
  );
}
