// TODO: Retrive from backend
export default [
  {
    name: 'protetor',
    label: 'Protetor',
    description: 'Participo de Causa Animal',
  },
  {
    name: 'tutor',
    label: 'Tutor',
    description: 'Possuo Animal de Estimação',
  },
  {
    name: 'segmentoPet',
    label: 'Segmento Pet',
    description: 'Participo de Empresa Pet',
  },
  {
    name: 'segmentoPublico',
    label: 'Segmento Público',
    description: 'Cczs, Secreatarias, etc',
  },
  {
    name: 'outros',
    label: 'Outros',
    description: 'Outros segmentos',
  },
];
