import styled from 'styled-components';
import { px2vw } from '../../utils/px2vw';
import LightenDarkenColor from '../../utils/LightenDarkenColor';
import { StyledButton } from '../../components/Button/styles';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  width: 100%;
  font-family: 'Circular Std';
  font-size: 2.875rem;
  text-align: center;
  color: #3d00c8;
  span {
    color: #00ee96;
  }

  @media only screen and (max-width: 600px) {
    font-size: 30px;
  }
`;

export const Text = styled.p`
  margin-top: 10px;
  margin-bottom: 26px;
  width: 70%;
  font-family: 'Circular Std';
  font-size: 1.125rem;
  text-align: center;
  color: #8c8c8c;

  @media only screen and (max-width: 600px) {
    font-size: 15px;
    width: 90%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;

  @media only screen and (max-width: 600px) {
    padding: 10px;
    padding-bottom: 30px;
  }
`;

export const Image = styled.div`
  display: flex;
  width: ${px2vw(783)};
  height: ${px2vw(737)};
  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;

export const Button = styled(StyledButton)`
  font-size: ${px2vw(17)};
  padding: 12px 52px;

  &:hover {
    background-color: ${props => LightenDarkenColor(props.bgColor, -30)};
  }

  @media only screen and (max-width: 600px) {
    width: 70%;
    font-size: 13px;
    padding: 10px 10px;
  }
`;

export const ButtonContainer = styled.a`
  @media only screen and (max-width: 600px) {
    width: 70%;
    font-size: 13px;
    padding: 10px 10px;
  }
`;
