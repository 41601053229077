import React, { useState, useEffect } from 'react';

import {
  Container,
  TitleAndDescriptionContainer,
  Title,
  Description,
  ContentContainerForm,
  HelpTypeContainer,
  HelpTypeTitle,
  Badge,
  HelpTypeInput,
  HelpTypeOptionsContainer,
  HelpDescriptionContainer,
  HelpDescriptionContent,
  HelpDescriptionTextArea,
  HelpDescriptionTextLabel,
  WordCounterContainer,
  WordCounter,
  ButtonContainer,
  Button,
  PublishAsContainer,
  LoggedOutText,
  SelectInput,
  Tip,
} from './styles';

import getHelpTypeConfigs from './getHelpConfigs';
import { helpOptions } from './helpOptions';
import Logo from '../../assets/images/logo_ajuda_pet.png';
import { Link, Redirect } from '@reach/router';
import { useAuth } from '../../context/auth';
import useCallbackStatus from '../../utils/useCallbackStatus';
import ErrorHolder from '../../components/ErrorHolder';
import { Spin } from '../../components/FullPageSpinner/styles';
import SuccessHolder from '../../components/SuccessHolder';
import { bootstrapAppData } from '../../utils/bootstrapAppData';

export default function AskForHelp() {
  const {
    data,
    isLoggedIn,
    hasInstitutionRegistered,
    createHelp,
    roleOptions,
  } = useAuth();
  const {
    isSuccess,
    isPending,
    isRejected,
    response,
    error,
    run,
    reload,
  } = useCallbackStatus();

  const [form, setForm] = useState({
    helpTypes: {
      racao: false,
      medicamento: false,
      dinheiro: false,
      outros: false,
    },
    description: '',
  });
  const [selectedRole, setSelectedRole] = useState(roleOptions[0].id);
  const [isRedirect, setRedirect] = useState(false);
  const [hasSelectedAnyHelpType, setHasSelectedAnyHelpType] = useState(null);

  if (isSuccess) {
    setTimeout(() => {
      setRedirect(true);
    }, 2000);
  }

  useEffect(() => {
    bootstrapAppData().then(reload);
  }, []);

  const onChangeInputTextValue = e => {
    const name = e.target.name;
    const value = e.target.value;

    setForm({ ...form, helpTypes: { ...form.helpTypes }, [name]: value });
  };

  const onChangeCheckboxValue = e => {
    const name = e.target.name;
    const value = e.target.checked;

    const previousSelected = Object.entries(form.helpTypes).filter(
      help => help[1] === true
    );
    const notSelectedItems = Object.entries(form.helpTypes).filter(
      help => help[1] === false && help[0] !== name
    );

    let filteredTypes = {};
    notSelectedItems.forEach(item => {
      filteredTypes[item.key] = item.value;
    });

    setForm({
      ...form,
      helpTypes: {
        ...filteredTypes,
        [previousSelected[0]]: previousSelected[1],
        [name]: value,
      },
    });
  };

  const onChangeInput = e => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setSelectedRole(value);
  };

  const onTryCreateHelp = e => {
    e.preventDefault();

    if (
      Object.values(form.helpTypes).filter(help => help === true).length !== 0
    ) {
      setHasSelectedAnyHelpType(true);
      const formData = {
        user_id: data.user.data._id,
        institution_id: selectedRole !== '0' ? selectedRole : null,
        description: form.description,
        helpNeeded: {},
      };
      for (let [key, value] of Object.entries(form.helpTypes)) {
        if (value === true) {
          formData.helpNeeded['name'] = key;
          formData.helpNeeded['active'] = value;
        }
      }

      run(createHelp(formData));
    } else {
      setHasSelectedAnyHelpType(false);
    }
  };

  return (
    <Container>
      <TitleAndDescriptionContainer>
        <Link to="/">
          <img style={{ marginTop: '20px' }} src={Logo} alt="Logo AjudaPet" />
        </Link>
        <Title>Solicite uma ajuda!</Title>
        <Description>
          Selecione sua(s) necessidade(s) e escreva algo sobre ela(s).
        </Description>
      </TitleAndDescriptionContainer>

      <ContentContainerForm>
        <HelpTypeContainer>
          <HelpTypeTitle>Do que você precisa?</HelpTypeTitle>
          <HelpTypeOptionsContainer>
            {helpOptions.map(option => {
              const helpTypeConfigs = getHelpTypeConfigs(option.name);
              return (
                <>
                  <HelpTypeInput
                    required
                    onChange={onChangeCheckboxValue}
                    checked={form.helpTypes[option.name]}
                    type="checkbox"
                    name={option.name}
                    id={option.name}
                  />
                  <Badge
                    isSelected={form.helpTypes[option.name]}
                    htmlFor={option.name}
                    bgColor={helpTypeConfigs.color}
                  >
                    {helpTypeConfigs.label}
                  </Badge>
                </>
              );
            })}
          </HelpTypeOptionsContainer>
        </HelpTypeContainer>
        <Tip>
          *Voce pode escolher somente um tipo de ajuda, caso necessite de mais
          alguma ajuda cadastre um nova publicação.
        </Tip>
        <HelpDescriptionContainer>
          <HelpDescriptionContent>
            <HelpDescriptionTextArea
              required
              name="description"
              value={form.description}
              onChange={onChangeInputTextValue}
              rows="10"
              maxLength={200}
            />
            <HelpDescriptionTextLabel>
              Descreva sua necessidade
            </HelpDescriptionTextLabel>
          </HelpDescriptionContent>
          <WordCounterContainer>
            <WordCounter>
              {form.description.length} de 200 caracteres
            </WordCounter>
          </WordCounterContainer>
        </HelpDescriptionContainer>

        <PublishAsContainer>
          {isLoggedIn ? (
            <p>
              Publicar como:{' '}
              {!hasInstitutionRegistered && (
                <strong>{data.user.data.nome}</strong>
              )}
              {!hasInstitutionRegistered && (
                <>
                  {' '}
                  ou{' '}
                  <Link to="/cadastro-instituicao">
                    <u>cadastre uma instituição!</u>
                  </Link>
                </>
              )}
              <br />
              {hasInstitutionRegistered && (
                <SelectInput
                  name="type"
                  onChange={onChangeInput}
                  value={selectedRole}
                >
                  {roleOptions.map(role => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </SelectInput>
              )}
            </p>
          ) : (
            <LoggedOutText>
              Faça <Link to="/login">login</Link> para solicitar uma ajuda!
            </LoggedOutText>
          )}
        </PublishAsContainer>

        <ButtonContainer>
          <Button
            disabled={!isLoggedIn}
            onClick={onTryCreateHelp}
            width={400}
            bgColor="#3D00C8"
          >
            Publicar
          </Button>
        </ButtonContainer>

        {isRejected && error && (
          <ErrorHolder
            style={{ textAlign: 'center', marginTop: '20px' }}
            errors={error ? error : null}
          />
        )}
        {isPending && <Spin />}
        {isSuccess && <SuccessHolder message={response && response.message} />}
        {isRedirect && <Redirect noThrow to="/" />}
        {hasSelectedAnyHelpType !== null && !hasSelectedAnyHelpType && (
          <ErrorHolder
            style={{ textAlign: 'center', marginTop: '20px' }}
            errors={['Selecione pelo menos 1 tipo de ajuda!']}
          />
        )}
      </ContentContainerForm>
    </Container>
  );
}
