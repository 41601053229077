import React, { useState, useEffect } from 'react';

import {
  Container,
  Box,
  ProfileImageContainer,
  Name,
  Info,
  InfoInstitutionName,
  InfoDescription,
} from './styles';
import Header from '../../components/Header';
import { useAuth } from '../../context/auth';
import { Spin } from '../../components/FullPageSpinner/styles';
import { getInstitutionBy, getUser } from '../../services/auth-client';
import NoAvatar from '../../assets/images/noAvatar.png';
import dayjs from 'dayjs';
import {
  NeedsContainer,
  HelpTypesContainer,
  Badge,
  WhatsAppButton,
  Icon,
} from '../HelpBlog/styles';
import getHelpTypeConfigs from '../AskForHelp/getHelpConfigs';
import WhatsApp from '../../assets/Icons/WhatsApp';

function SingleHelp({ helpId }) {
  const { getHelpById } = useAuth();
  const [help, setHelp] = useState({});

  useEffect(() => {
    async function fetchInstitution(institutionId) {
      const res = await getInstitutionBy(institutionId);
      if (res && res.data !== null) {
        return Promise.resolve(res.data);
      }
      return Promise.reject(res);
    }

    async function fetchUser(userId) {
      const res = await getUser(userId);
      if (res && res.data) {
        return Promise.resolve(res.data);
      }

      return Promise.reject(res);
    }

    const fetchHelp = async () => {
      const fetchedHelp = await getHelpById(helpId);
      const user = await fetchUser(fetchedHelp.user_id);
      let helpToDisplay = null;
      let institution = null;

      if (fetchedHelp && fetchedHelp.institution_id) {
        institution = await fetchInstitution(fetchedHelp.institution_id).catch(
          e => e
        );
      }

      helpToDisplay = {
        description: fetchedHelp.description,
        helpNeeded: fetchedHelp.helpNeeded.name,
        user: user,
        institution: (institution && institution) || null,
        createdAt: dayjs(fetchedHelp.createdAt).toString(),
      };

      setHelp(helpToDisplay);
    };

    fetchHelp();
  }, []);

  return (
    <>
      <Header />
      {Object.entries(help).length !== 0 && help.user ? (
        <Container>
          <Box>
            <ProfileImageContainer>
              <img src={resolveAvatar(help)} alt="Imagem de perfil" />
            </ProfileImageContainer>
            <Name>{help.user.nome}</Name>
            <InfoInstitutionName>
              {help.institution && help.institution.nome}
            </InfoInstitutionName>
            <Info>
              {dayjs(help.createdAt)
                .locale('pt-br')
                .format('DD [de] MMMM [às] HH:mm')}
            </Info>

            <NeedsContainer>
              <HelpTypesContainer style={{ alignItems: 'baseline' }}>
                Precisa de:
                <Badge bgColor={getHelpTypeConfigs(help.helpNeeded).color}>
                  {getHelpTypeConfigs(help.helpNeeded).label}
                </Badge>
              </HelpTypesContainer>
            </NeedsContainer>

            <InfoDescription>{help.description}</InfoDescription>
            <div style={{ width: '80%', padding: '0' }}>
              <WhatsAppButton
                style={{ marginTop: '40px', marginLeft: '0' }}
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=${
                  help.user.telefone.substring(0, 3) === '+55'
                    ? help.user.telefone.substring(1, help.user.telefone.length)
                    : `55${help.user.telefone}`
                }&text=Ol%C3%A1%20${
                  help.user.nome
                }%2C%20vi%20que%20precisa%20de%20${
                  help.helpNeeded
                }%20e%20gostaria%20de%20ajudar!`}
              >
                <Icon>
                  <WhatsApp />
                </Icon>
                Posso ajudar!
              </WhatsAppButton>
            </div>
          </Box>
        </Container>
      ) : (
        <Spin />
      )}
    </>
  );
}

const resolveAvatar = help => {
  if (help.institution && help.institution.logo !== null) {
    return help.institution.logo;
  } else if (help.user && help.user.avatar !== null) {
    return help.user.avatar;
  } else {
    return NoAvatar;
  }
};

function isMobile() {
  return window.innerWidth <= 718;
}

export default SingleHelp;
