import client from './api-client';
import Axios from 'axios';
import { getBaseURL } from '../utils/env';

const localStorageKey = '$TOKEN';

function handleForgotPassswordReponse({
  success,
  message,
  errors = null,
  ...rest
}) {
  if (errors) {
    return Promise.reject(errors);
  }

  return { success, message, ...rest };
}

function handleUserResponse({ data = null, errors = null, ...rest }) {
  window.localStorage.setItem(localStorageKey, data && data.token);

  if (errors) {
    logout();
    return Promise.reject(errors);
  }
  return rest;
}

function handleRegisterUser({ success, message, errors = null, ...rest }) {
  if (errors) {
    return Promise.reject(errors);
  }

  return { success, message, ...rest };
}

function handleUpdateUser({ data, errors = null, ...rest }) {
  if (errors) {
    return Promise.reject(errors);
  }

  return { ...data, ...rest };
}

function login(email, password) {
  return client('account/authenticate', {
    body: { email, password },
  }).then(handleUserResponse);
}

function logout() {
  window.localStorage.removeItem(localStorageKey);

  return Promise.resolve();
}

function register({ name, email, password, phone, tipo }) {
  return client('account/register', {
    body: {
      nome: name,
      email: email,
      senha: password,
      telefone: phone,
      tipo: tipo,
    },
  }).then(handleRegisterUser);
}

function registerInstitution({
  userId,
  name,
  email,
  type,
  celular,
  cep,
  bairro,
  logradouro,
  cidade,
  estado,
  numero,
  complemento,
}) {
  return client('institutions', {
    body: {
      usuario: userId,
      nome: name,
      email: email,
      tipo: type,
      celular: celular,
      cep: cep,
      bairro: bairro,
      logradouro: logradouro,
      cidade: cidade,
      estado: estado,
      numero: numero,
      complemento: complemento,
    },
  }).then(handleRegisterUser);
}

function getUserInstitutions(userId) {
  return client(`institutions/user/${userId}`);
}

function getInstitutionBy(institutionId) {
  return client(`institutions/${institutionId}`);
}

function getCurrentUser() {
  const token = getToken();

  if (!token) {
    return Promise.resolve(null);
  }
  return client('users/me')
    .then(res => {
      if (res && res.data === null) {
        logout();
      }
      return Promise.resolve(res);
    })
    .catch(error => {
      logout();
      return Promise.reject(error);
    });
}

function updateUserAvatar(image, userId) {
  let formData = new FormData();
  formData.append('avatar', image);
  formData.append('user_id', userId);

  return Axios.put(`${getBaseURL()}/users/avatar`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .catch(e => e.response)
    .then(handleUpdateUser);
}

function updateInstitutionAvatar(image, institutionId) {
  let formData = new FormData();
  formData.append('avatar', image);
  formData.append('institution_id', institutionId);

  return Axios.put(`${getBaseURL()}/institutions/avatar`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .catch(e => e.response)
    .then(handleUpdateUser);
}

function forgotPassword(email) {
  return client('account/help/forgot-password', { body: { email } }).then(
    handleForgotPassswordReponse
  );
}

function updateUserInfo({ ...data }) {
  const dataToSend = {
    _id: data.id,
    nome: data.name,
    telefone: data.phone,
    email: data.email,
  };
  return Axios.put(`${getBaseURL()}/users`, dataToSend, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .catch(e => e.response.data)
    .then(handleUpdateUser);
}

function updateInstitutionInfo({ ...data }) {
  const dataToSend = {
    _id: data.id,
    nome: data.name,
    celular: data.phone,
    email: data.email,
    tipo: data.type,
    logradouro: data.street,
    bairro: data.neighborhood,
    numero: data.number,
    cidade: data.city,
    usuario: data.usuario,
    estado: data.estado,
    complemento: data.complemento,
    logo: data.logo,
  };
  return Axios.put(`${getBaseURL()}/institutions`, dataToSend, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
    .catch(e => e.response.data)
    .then(handleUpdateUser);
}

function getUser(userId) {
  return client(`users/help/${userId}`);
}

function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

export {
  login,
  logout,
  register,
  registerInstitution,
  getCurrentUser,
  getToken,
  getUserInstitutions,
  getUser,
  getInstitutionBy,
  updateUserAvatar,
  updateUserInfo,
  updateInstitutionAvatar,
  updateInstitutionInfo,
  forgotPassword,
};
