import React from 'react';

import { Container } from './styles';
import Logo from '../../assets/images/logo_ajuda_pet.png';
import { Link } from '@reach/router';

function Footer() {
  return (
    <Container>
      <p>
        <Link to="/">
          <img src={Logo} alt="Logo AjudaPet" />
        </Link>
      </p>
    </Container>
  );
}

export default Footer;
