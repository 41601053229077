import React from 'react';

import { Container, Message } from './styles';

function SuccessHolder({ message, ...rest }) {
  return (
    <Container>{message && <Message {...rest}>{message}</Message>}</Container>
  );
}

export default SuccessHolder;
