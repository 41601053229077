import React, { useState } from 'react';

import {
  Container,
  Box,
  Input,
  SelectInput,
  Button,
  LoggedOutText,
  MultipleElementsContainer,
  CepButton,
} from './styles';
import Logo from '../../assets/images/logo_ajuda_pet.png';
import useCallbackStatus from '../../utils/useCallbackStatus';
import { useAuth } from '../../context/auth';
import { Link, Redirect } from '@reach/router';
import ErrorHolder from '../../components/ErrorHolder';
import { Spin } from '../../components/FullPageSpinner/styles';
import SuccessHolder from '../../components/SuccessHolder';

import { getAddress } from '../../services/help-client';

const instituitionTypes = [
  { name: 'ONG', value: 'ong' },
  { name: 'Lar Temporário', value: 'larTemporario' },
  { name: 'Abrigo', value: 'abrigo' },
  { name: 'Centro de Zoonozes', value: 'centroZoonoses' },
  { name: 'Clínica Veterinária', value: "'clinicaVeterinaria" },
  { name: 'Laboratório', value: 'laboratorio' },
  { name: 'Pet Shop', value: 'petShop' },
  { name: 'Profissional Autônomo', value: 'profissionalAutonomo' },
  { name: 'Feira de Adoção', value: 'feiraAdocao' },
  { name: 'Outros', value: 'outros' },
];

function RegisterInstitution() {
  const { registerInstitution, isLoggedIn, data, refreshPage } = useAuth();
  const {
    isSuccess,
    isPending,
    isRejected,
    response,
    error,
    run,
  } = useCallbackStatus();
  const [form, setForm] = useState({
    name: '',
    email: '',
    type: 'ong',
    celular: '',
    cep: '',
    bairro: '',
    logradouro: '',
    cidade: '',
    estado: '',
    numero: '',
    complemento: '',
  });
  const [isRedirect, setRedirect] = useState(false);

  if (isSuccess) {
    setTimeout(() => {
      setRedirect(true);
    }, 2000);
  }

  const onChangeInput = e => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setForm({ ...form, [name]: value });
  };

  const onTryRegisterInstitution = async e => {
    e.preventDefault();
    const userId = isLoggedIn && data.user.data._id;

    run(registerInstitution({ userId, ...form }));
    refreshPage();
  };

  const onTryFetchAddress = async e => {
    e.preventDefault();
    try {
      const res = await getAddress(form.cep);
      if (res && res.data) {
        setForm({
          ...form,
          logradouro: res.data.logradouro,
          bairro: res.data.bairro,
          cidade: res.data.cidade,
          estado: res.data.estado,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Box>
        <Link to="/">
          <img src={Logo} alt="Logo AjudaPet" />
        </Link>

        <h2>Cadastro de Instituição</h2>
        <form>
          <Input
            required
            onChange={onChangeInput}
            value={form.name}
            name="name"
            type="text"
            placeholder="Nome"
          />
          <Input
            required
            onChange={onChangeInput}
            value={form.email}
            name="email"
            type="email"
            placeholder="E-mail"
          />
          <SelectInput name="type" onChange={onChangeInput} value={form.type}>
            {instituitionTypes.map(type => (
              <option key={type.value} value={type.value}>
                {type.name}
              </option>
            ))}
          </SelectInput>
          <Input
            required
            onChange={onChangeInput}
            value={form.celular}
            name="celular"
            type="text"
            maxLength={10}
            placeholder="Telefone"
          />
          <MultipleElementsContainer>
            <Input
              style={{ width: '200px' }}
              required
              onChange={onChangeInput}
              value={form.cep}
              maxLength={8}
              name="cep"
              type="text"
              placeholder="CEP (Ex: 31867309)"
            />
            <CepButton onClick={onTryFetchAddress}>
              Clique aqui para puxar localização
            </CepButton>
          </MultipleElementsContainer>
          <Input
            required
            onChange={onChangeInput}
            value={form.logradouro}
            maxLength={9}
            name="logradouro"
            type="text"
            placeholder="Rua"
          />
          <Input
            required
            onChange={onChangeInput}
            value={form.bairro}
            maxLength={9}
            name="bairro"
            type="text"
            placeholder="Bairro"
          />
          <Input
            required
            onChange={onChangeInput}
            value={form.cidade}
            maxLength={9}
            name="cidade"
            type="text"
            placeholder="Cidade"
          />
          <Input
            required
            onChange={onChangeInput}
            value={form.estado}
            maxLength={9}
            name="estado"
            type="text"
            placeholder="Estado"
          />
          <MultipleElementsContainer>
            <Input
              style={{ width: '40%' }}
              required
              onChange={onChangeInput}
              value={form.numero}
              maxLength={9}
              name="numero"
              type="text"
              placeholder="Numero"
            />
            <Input
              style={{ width: '60%' }}
              required
              onChange={onChangeInput}
              value={form.complemento}
              maxLength={9}
              name="complemento"
              type="text"
              placeholder="Complemento"
            />
          </MultipleElementsContainer>
          <Button onClick={onTryRegisterInstitution} bgColor="#3D00C8">
            Cadastrar
          </Button>
        </form>

        {isRejected && error && <ErrorHolder errors={error ? error : null} />}
        {isPending && <Spin />}
        {isSuccess && <SuccessHolder message={response && response.message} />}
        {isRedirect && <Redirect noThrow to="/" />}
      </Box>
    </Container>
  );
}

export default RegisterInstitution;
