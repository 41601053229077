import client from './api-client';

function handleHelpResponse({ data = null, errors = null }) {
  if (errors) {
    return Promise.reject(errors);
  }
  return data;
}

function handleCreateHelp({ success, message, errors = null, ...rest }) {
  if (errors) {
    return Promise.reject(errors);
  }

  return { success, message, ...rest };
}

function createHelp({
  user_id,
  institution_id = null,
  description,
  helpNeeded,
}) {
  return client('help', {
    body: { user_id, description, institution_id, helpNeeded },
  }).then(handleCreateHelp);
}

function getHelps() {
  return client('help').then(handleHelpResponse);
}

function getHelpById(id) {
  return client(`help/${id}`).then(handleHelpResponse);
}

function getAddress(cep) {
  return client(`zipCode/${cep}`);
}

export { createHelp, getHelps, getAddress, getHelpById };
