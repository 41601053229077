import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { Container } from './styles';

export const WhatsAppFloatingButton = () => (
  <Container
    data-tooltip="Dúvidas/Sugestões? Clique aqui"
    data-placement="left"
  >
    <a
      style={{ textDecoration: 'none', color: 'white' }}
      target="_blank"
      href="https://api.whatsapp.com/send?phone=5531988884400&text=Ol%C3%A1%20AjudaPet%2C%20pode%20me%20ajudar%3F"
    >
      <FontAwesomeIcon icon={faWhatsapp} />
    </a>
  </Container>
);
