import React from 'react';
import { Container, Message } from './styles';

function ErrorHolder({ errors, ...rest }) {
  return (
    <Container {...rest}>
      {errors &&
        errors.map((error, index) => <Message key={index}>{error}</Message>)}
    </Container>
  );
}

export default ErrorHolder;
