import styled from 'styled-components';
import { shade } from 'polished';
import { px2vw } from '../../utils/px2vw';
import { StyledButton } from '../../components/Button/styles';
import LightenDarkenColor from '../../utils/LightenDarkenColor';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00ee96;

  @media only screen and (max-width: 600px) {
    height: 100vh;
    align-items: flex-start;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${px2vw(540)};
  height: ${px2vw(580)};
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: ${px2vw(48)} 0;

  @media only screen and (max-width: 600px) {
    width: 90%;
    height: 440px;
    font-size: 13px;
    margin-top: 40px;
  }

  img {
    width: ${px2vw(232)};
    height: ${px2vw(110)};

    @media only screen and (max-width: 600px) {
      width: 100%;
      height: fit-content;
    }
  }

  form {
    margin-top: ${px2vw(39)};
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 600px) {
      width: 80%;
    }

    a {
      margin-top: 4px;
      @media only screen and (max-width: 600px) {
        text-align: center;
      }
    }

    button {
      flex: 1;
      margin-top: 22px;
      margin-right: 0;

      &:hover {
        background-color: ${shade(0.2, '#3D00C8')};
      }
    }

    button + button {
      margin-top: 8px;
    }
  }

  & > button {
    margin-top: 8px;
  }

  & > div {
    display: flex;
    margin-top: ${px2vw(30)};

    @media only screen and (max-width: 600px) {
      margin-top: 20px;
    }

    .kep-login-facebook {
      width: ${px2vw(160)};
      height: 44px;
      margin-right: ${px2vw(17)};
      font-size: 0.75rem !important;
      text-align: center;
      padding: 0;

      @media only screen and (max-width: 600px) {
        width: 100%;
        font-size: 12px !important;
        padding: 10px;
      }
    }
  }
`;

export const Input = styled.input`
  position: relative;
  width: ${px2vw(338)};
  height: ${px2vw(48)};
  border: 1px solid #3d00c8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 ${px2vw(16)};
  font-size: 1rem;
  margin-bottom: ${px2vw(16)};

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 40px;
    font-size: 13px;
    padding: 10px;
  }

  & + input {
    margin-bottom: 0;
  }

  &:focus {
    border: 2px solid #3d00c8;
  }
`;

export const Button = styled(StyledButton)`
  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 13px;
  }

  &:hover {
    color: ${props => props.color && LightenDarkenColor(props.color, -30)};
  }
`;

export const SocialNetworksContainer = styled.div`
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50px;

  @media only screen and (max-width: 600px) {
    height: 40px;
    margin-top: 30px;
  }
`;

export const Error = styled.div``;
