import React, { useState } from 'react';

import { Container, Box, Input, Button } from './styles';
import { Link } from '@reach/router';
import Logo from '../../assets/images/logo_ajuda_pet.png';
import { useAuth } from '../../context/auth';
import useCallbackStatus from '../../utils/useCallbackStatus';
import ErrorHolder from '../../components/ErrorHolder';
import { Spin } from '../../components/FullPageSpinner/styles';
import SuccessHolder from '../../components/SuccessHolder';

function ForgotPassword() {
  const { forgotPassword } = useAuth();
  const {
    isPending,
    isRejected,
    error,
    isSuccess,
    response,
    run,
  } = useCallbackStatus();
  const [email, setEmail] = useState('');

  const onTryRecoverPassword = async e => {
    e.preventDefault();
    if (email.length !== 0) {
      run(forgotPassword(email));
    }
  };

  return (
    <Container>
      <Box>
        <Link to="/">
          <img src={Logo} alt="Logo AjudaPet" />
        </Link>
        <h2>Recuperar senha</h2>
        <form>
          <Input
            onChange={e => setEmail(e.target.value)}
            value={email}
            name="email"
            type="email"
            placeholder="Digite seu E-mail"
          />
          <Button onClick={onTryRecoverPassword} bgColor="#3D00C8">
            Recuperar senha
          </Button>
        </form>
        {isRejected && <ErrorHolder errors={error ? error : null} />}
        {isPending && <Spin />}
        {isSuccess && (
          <>
            <SuccessHolder
              style={{ width: isMobile() ? '300px' : '400px' }}
              message={response && response.message}
            />
            <Link to="/login">
              <div style={{ fontSize: '17px', marginTop: '20px' }}>
                Voltar para página de login
              </div>
            </Link>
          </>
        )}
      </Box>
    </Container>
  );
}

function isMobile() {
  return window.innerWidth <= 718;
}

export default ForgotPassword;
