import styled from 'styled-components';

export const Container = styled.div`
  right: 25px;
  bottom: 25px;
  position: fixed;
  z-index: 9999;
  background-color: #25d366;
  color: #fff;
  line-height: 55px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  font-size: 23px;
  width: 55px;
  height: 55px;
  text-align: center;
  box-shadow: 2px 2px 8px -3px #000;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  display: inline-block;

  > a {
    position: relative;
  }
`;

export const Whatsapp = styled.i``;
