import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Message = styled.div`
  color: red;
  margin: 0 auto;
  bottom: 15px;
  font-size: 17px;

  @media only screen and (max-width: 600px) {
    margin-top: 20px;
  }
`;
