import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';

import {
  Container,
  HeaderContainer,
  ImageContainer,
  Title,
  PostsContainer,
  SearchInput,
  InputWrapper,
  Post,
  PostHeaderContainer,
  UserInfo,
  Badge,
  Message,
  Icon,
  WhatsAppButton,
  HeaderPostsContainer,
  PostsTitle,
  HelpTypesContainer,
  NeedsContainer,
  ShareIconContainer,
} from './styles';

import Hands from '../../assets/images/hands.png';
import NoAvatar from '../../assets/images/noAvatar.png';

import WhatsApp from '../../assets/Icons/WhatsApp';
import getHelpTypeConfigs from '../AskForHelp/getHelpConfigs';
import dayjs from 'dayjs';
import { useAuth } from '../../context/auth';
import { getInstitutionBy, getUser } from '../../services/auth-client';
import { Spin } from '../../components/FullPageSpinner/styles';

export default function HelpBlog({ blogRef }) {
  const { getHelps } = useAuth();
  const [helps, setHelps] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchInstitution(institutionId) {
      const res = await getInstitutionBy(institutionId);
      if (res && res.data !== null) {
        return Promise.resolve(res.data);
      }
      return Promise.reject(res);
    }

    async function fetchUser(userId) {
      const res = await getUser(userId);
      if (res && res.data) {
        return Promise.resolve(res.data);
      }

      return Promise.reject(res);
    }

    async function fetchFormatedHelps() {
      const fetchedHelps = await getHelps();
      let helpsToDisplay = [];

      setLoading(true);
      Promise.all(
        fetchedHelps.map(async help => {
          const user = await fetchUser(help.user_id);
          let institution = null;
          if (help && help.institution_id) {
            institution = await fetchInstitution(help.institution_id).catch(
              e => e
            );
          }

          helpsToDisplay.push({
            id: help._id,
            description: help.description,
            helpNeeded: help.helpNeeded.name,
            user: user,
            institution: (institution && institution) || null,
            createdAt: dayjs(help.createdAt).toString(),
          });
        })
      )
        .then(() => {
          setLoading(false);
          const sortedHelps = helpsToDisplay.sort((a, b) =>
            dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
          );
          setHelps(sortedHelps);
        })
        .catch(e => {
          console.log(e);
        });
    }

    fetchFormatedHelps();
  }, []);

  const onTryShareHelp = async help => {
    const name = help.institution ? help.institution.nome : help.user.nome;
    const helpNeeded = getHelpTypeConfigs(help.helpNeeded).label;
    const description = help.description;

    if (isMobile()) {
      try {
        await navigator.share({
          text: `${name} está precisando de ${helpNeeded}!\n\n "${description}"\n\n Clique no link abaixo para saber mais detalhes para realizar a ajuda!\n\n\n`,
          url: `https://beta.ajudapet.org/ajuda/${help.id}`,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Container ref={blogRef}>
      <HeaderContainer>
        <ImageContainer src={Hands} alt="Mãos solidárias" />
        <span>
          <Title fontSize="1.975rem">
            Criamos uma <strong>rede de colaboração</strong> em prol dos
            animais.
          </Title>
          <Title
            fontSize="2.625rem"
            color="#00ee96"
            marginTop={18}
            fontWeight="bold"
          >
            Você também pode fazer parte!
          </Title>
        </span>
      </HeaderContainer>
      <PostsContainer>
        <HeaderPostsContainer>
          <PostsTitle
            color="#050050"
            fontSize="1.375rem"
            fontWeight="bold"
            textAlign="left"
          >
            Últimas postagens
          </PostsTitle>
        </HeaderPostsContainer>
        {helps &&
          helps.length > 0 &&
          helps.map((help, index) => {
            const avatarToDisplay = resolveAvatar(help);
            const formatedPhone =
              help.user.telefone.substring(0, 3) === '+55'
                ? help.user.telefone.substring(1, help.user.telefone.length)
                : `55${help.user.telefone}`;

            return (
              <Post key={index}>
                <PostHeaderContainer>
                  <UserInfo>
                    <img src={avatarToDisplay} alt="sem avatar" />
                    <div>
                      <h2>{help.user.nome}</h2>
                      <p>
                        <strong>
                          {help.institution && help.institution.nome}
                        </strong>
                      </p>
                      <p>
                        {dayjs(help.createdAt)
                          .locale('pt-br')
                          .format('DD [de] MMMM [às] HH:mm')}
                      </p>
                    </div>
                  </UserInfo>
                  <NeedsContainer>
                    <p>Precisa de:</p>
                    <HelpTypesContainer>
                      <Badge
                        bgColor={getHelpTypeConfigs(help.helpNeeded).color}
                      >
                        {getHelpTypeConfigs(help.helpNeeded).label}
                      </Badge>
                    </HelpTypesContainer>
                  </NeedsContainer>
                </PostHeaderContainer>
                <Message>{help.description}</Message>
                <WhatsAppButton
                  target="_blank"
                  href={`https://api.whatsapp.com/send?phone=${formatedPhone}&text=Ol%C3%A1%20${help.user.nome}%2C%20vi%20que%20precisa%20de%20${help.helpNeeded}%20e%20gostaria%20de%20ajudar!`}
                >
                  <Icon>
                    <WhatsApp />
                  </Icon>
                  Posso ajudar!
                </WhatsAppButton>
                {isMobile() && (
                  <ShareIconContainer onClick={() => onTryShareHelp(help)}>
                    <FontAwesomeIcon size="5x" icon={faShareAlt} />
                  </ShareIconContainer>
                )}
              </Post>
            );
          })}
        {!loading && helps && helps.length === 0 && (
          <h1
            style={{
              textAlign: 'center',
              fontSize: '26px',
              marginTop: '50px',
              marginBottom: '50px',
            }}
          >
            No momento não há ajudas cadastradas!
          </h1>
        )}
        {loading && (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Spin style={{ margin: '0 auto' }} />
          </div>
        )}
      </PostsContainer>
    </Container>
  );
}

const resolveAvatar = help => {
  if (help.institution && help.institution.logo !== null) {
    return help.institution.logo;
  } else if (help.user && help.user.avatar !== null) {
    return help.user.avatar;
  } else {
    return NoAvatar;
  }
};

function isMobile() {
  return window.innerWidth <= 718;
}
