import styled from 'styled-components';
import { px2vw } from '../../utils/px2vw';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${px2vw(540)};
  height: ${px2vw(600)};
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: ${px2vw(48)} 0;
  margin-bottom: 50px;
  margin-top: 50px;

  @media only screen and (max-width: 600px) {
    width: 90%;
    height: 630px;
    font-size: 13px;
    margin-top: 40px;
    padding-top: 30px;
  }
`;

export const ProfileImageContainer = styled.div`
  display: flex;

  img {
    width: ${px2vw(130)};
    height: ${px2vw(130)};
    border-radius: 50%;
    margin-right: ${px2vw(20)};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin-top: 30px;

    @media only screen and (max-width: 600px) {
      margin-top: 20px;
      width: 110px;
      height: 110px;
    }
  }
`;

export const Name = styled.h1`
  width: 100%;
  font-family: 'Circular Std';
  font-size: 2.275rem;
  text-align: center;
  color: #050050;
  margin-top: 20px;
  span {
    color: #00ee96;
  }

  @media only screen and (max-width: 600px) {
    font-size: 30px;
  }
`;

export const Info = styled.div`
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 18px;
  color: #333333;
  margin-top: 10px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const InfoInstitutionName = styled.div`
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: bolder;
  font-size: 1.4rem;
  line-height: 18px;
  color: #333333;
  margin-top: 10px;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const InfoDescription = styled.div`
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 30px;
  color: #333333;
  margin-top: 50px;
  width: 80%;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;
