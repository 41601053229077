export default function getHelpTypeConfigs(name) {
  switch (name) {
    case 'racao':
      return { color: '#C19E69', label: 'Ração' };
    case 'medicamento':
      return { color: '#75ACDF', label: 'Medicamento' };
    case 'dinheiro':
      return { color: '#A1CE96', label: 'Dinheiro' };
    case 'outros':
      return { color: '#b2b2b2', label: 'Outros' };
    default:
      return { color: '#b2b2b2', label: 'Outros' };
  }
}
