import React, { useState } from 'react';
import { Redirect, Link } from '@reach/router';
import { useAuth } from '../../context/auth';

import ErrorHolder from '../../components/ErrorHolder';

import {
  Container,
  Box,
  Input,
  Button,
  SocialNetworksContainer,
  ForgotPasswordContainer,
} from './styles';
import Logo from '../../assets/images/logo_ajuda_pet.png';
import useCallbackStatus from '../../utils/useCallbackStatus';
import { Spin } from '../../components/FullPageSpinner/styles';
import { getToken } from '../../services/auth-client';

const Login = () => {
  const { login } = useAuth();
  const { isPending, isRejected, error, run } = useCallbackStatus();
  const [form, setFrom] = useState({
    email: '',
    password: '',
  });

  const isSubmitable = form.email && form.password;

  const onChangeInput = e => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setFrom({ ...form, [name]: value });
  };

  const onTryLogin = async e => {
    e.preventDefault();

    if (isSubmitable) {
      run(login(form.email, form.password));
    }
  };

  return (
    <Container>
      <Box>
        <Link to="/">
          <img src={Logo} alt="Logo AjudaPet" />
        </Link>

        <form>
          <Input
            onChange={onChangeInput}
            value={form.email}
            name="email"
            type="email"
            placeholder="E-mail"
          />
          <Input
            onChange={onChangeInput}
            value={form.password}
            name="password"
            type="password"
            placeholder="Senha"
          />
          <Button onClick={onTryLogin} bgColor="#3D00C8">
            Entrar
          </Button>
        </form>
        <ForgotPasswordContainer>
          <Link to="/esqueci-senha">
            <Button
              style={{ marginRight: '0', fontWeight: '0' }}
              isLink
              color="#3AB286"
            >
              Esqueci minha senha
            </Button>
          </Link>
          <Link to="/cadastro">
            <Button isLink color="#3D00C8">
              Ou cadadastre-se
            </Button>
          </Link>
        </ForgotPasswordContainer>
        {isRejected && <ErrorHolder errors={error ? error : null} />}
        {isPending && <Spin />}
        {getToken() !== null && <Redirect noThrow to="/" />}
      </Box>
    </Container>
  );
};

export default Login;
