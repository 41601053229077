import styled from 'styled-components';
import { px2vw } from '../../utils/px2vw';
import LightenDarkenColor from '../../utils/LightenDarkenColor';
import { StyledButton } from '../../components/Button/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  overflow: hidden;
`;

export const TitleAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h1`
  text-align: center;
  color: #050050;
  font-size: 40px;
  margin-top: 10px;

  @media only screen and (max-width: 600px) {
    font-size: 30px;
  }
`;

export const Description = styled.p`
  color: #777b92;
  font-size: 20px;
  margin-top: 11px;

  @media only screen and (max-width: 600px) {
    font-size: 17px;
    text-align: center;
    width: 90%;
  }
`;

export const ContentContainerForm = styled.form`
  width: 650px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 50px;
  margin: 0 auto;
  margin-top: 40px;

  @media only screen and (max-width: 600px) {
    width: 90%;
    padding: 30px;
  }
`;

export const HelpTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const HelpTypeTitle = styled.p`
  color: #050050;
  font-size: 20px;
  font-weight: bold;
`;

export const HelpTypeOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 180px;
  }
`;

export const Badge = styled.label`
  color: ${props => props.color || '#fff'};
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  border-radius: 10px;
  padding: 2px;
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 120px;
  background-color: ${props => (props.isSelected ? '#050050' : props.bgColor)};
  transition: all 0.2s ease-out;

  &:hover {
    background-color: ${props =>
      !props.isSelected && LightenDarkenColor(props.bgColor, -30)};
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding-top: 7px;
    font-size: 19px;
    height: 35px;
  }
`;

export const HelpTypeInput = styled.input`
  display: contents;
`;

export const HelpDescriptionContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  border-radius: 4px;
  position: relative;
`;

export const HelpDescriptionContent = styled.label`
  font-size: 1rem;
  margin-bottom: 15px;
  display: block;
`;

export const HelpDescriptionTextArea = styled.textarea`
  padding: 13px 15px;
  border-radius: 8px;
  border: 2px solid #1a73e8;
  outline: 0;
  width: 100%;
`;

export const HelpDescriptionTextLabel = styled.label`
  position: absolute;
  top: -7px;
  left: 10px;
  color: #1a73e8;
  font-size: 0.85rem;
  padding-right: 0.33rem;
  padding-left: 0.33rem;
  background: #fff;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  font-family: sans-serif;

  @media only screen and (max-width: 600px) {
    font-size: 11px;
  }
`;

export const WordCounterContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const WordCounter = styled.div`
  right: 0;
  top: -8px;
  font-size: 12px;
  color: #777b92;
  position: absolute;

  @media only screen and (max-width: 600px) {
    font-size: 11px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const Button = styled(StyledButton)`
  width: 300px;
  font-size: 18px;
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    font-size: 17px;
    width: 100%;
  }
`;

export const PublishAsContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
`;

export const LoggedOutText = styled.p`
  font-size: 18px;
  a {
    color: #050050;
  }
`;

export const SelectInput = styled.select`
  position: relative;
  width: ${px2vw(338)};
  height: ${px2vw(48)};
  border: 1px solid #3d00c8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 ${px2vw(16)};
  font-size: 1rem;
  margin-bottom: 10px;
  background-color: #ffffff;
  margin-top: 10px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 40px;
    font-size: 13px;
    padding: 10px;
  }

  &:focus {
    border: 2px solid #3d00c8;
  }
`;

export const Tip = styled.div`
  font-size: 13px;
  margin-top: 20px;
`;
