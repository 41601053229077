import { createGlobalStyle } from 'styled-components';
import { px2vw } from '../utils/px2vw';

import CircularStdWoff from '../assets/fonts/CircularStd-Medium.woff';
import CircularStdWoff2 from '../assets/fonts/CircularStd-Medium.woff2';
import CircularStdTtf from '../assets/fonts/CircularStd-Medium.ttf';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  :root {
    font-size: ${px2vw(16)};
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }
  button {
    cursor: pointer;
  }

  @font-face {
    font-family: 'Circular Std';
    src: local('Circular Std'), local('Circular-Std'),
        url(${CircularStdWoff2}) format('woff2'),
        url(${CircularStdWoff}) format('woff'),
        url(${CircularStdTtf}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }
`;

export default GlobalStyle;
