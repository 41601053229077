import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 16px !important;
`;

export const Message = styled.div`
  color: red;
  margin: 0 auto;
  bottom: 15px;
  font-weight: bolder;
  color: limegreen;
  font-size: 20px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    margin-top: 20px;
  }
`;
