import styled, { css } from 'styled-components';
import { px2vw } from '../../utils/px2vw';

const outline = css`
  background-color: transparent;
  border: 2px solid ${props => props.borderColor || '#050050'};
  color: ${props => props.borderColor || '#050050'};
`;

const link = css`
  background-color: unset;
  border: unset;
  color: ${props => props.color || '#050050'};
  padding: unset;
`;

export const StyledButton = styled.button`
  width: ${props => (props.width ? px2vw(props.width) : 'unset')};
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: ${props => props.fontWeight || 'bold'};
  color: ${props => props.color || '#fff'};
  padding: ${props => props.padding || '10px 32px'};
  background-color: ${props => props.bgColor || '#050050'};
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  user-select: none;
  margin-right: ${props => props.margin || '38px'};
  ${props => props.isOutline && outline};
  ${props => props.isLink && link};
  &:last-child {
    margin-right: 0;
  }
`;
