import React, { useState } from 'react';
import {
  Container,
  Box,
  Input,
  Button,
  CheckboxContainer,
  UserTypeCheckbox,
  UserTypeLabel,
} from './styles';
import useCallbackStatus from '../../utils/useCallbackStatus';
import { useAuth } from '../../context/auth';
import ErrorHolder from '../../components/ErrorHolder';
import { Spin } from '../../components/FullPageSpinner/styles';
import { Link, Redirect } from '@reach/router';
import Logo from '../../assets/images/logo_ajuda_pet.png';
import SuccessHolder from '../../components/SuccessHolder';
import userTypeOptions from './userTypeOptions';

function Register() {
  const { register } = useAuth();
  const {
    isSuccess,
    isPending,
    isRejected,
    response,
    error,
    run,
  } = useCallbackStatus();
  const [form, setForm] = useState({
    email: '',
    password: '',
    phone: '',
    name: '',
    sobrenome: '',
    tipo: {
      protetor: false,
      tutor: false,
      segmentoPet: false,
      segmentoPublico: false,
      outros: false,
    },
  });
  const [isRedirect, setRedirect] = useState(false);

  if (isSuccess) {
    setTimeout(() => {
      setRedirect(true);
    }, 2000);
  }

  const onChangeCheckboxValue = e => {
    const name = e.target.name;
    const value = e.target.checked;

    setForm({
      ...form,
      tipo: {
        ...form.tipo,
        [name]: value,
      },
    });
  };

  const onChangeInput = e => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setForm({ ...form, [name]: value });
  };

  const onTryRegister = async e => {
    e.preventDefault();
    const userTypeFiltered = Object.entries(form.tipo)
      .filter(type => type[1] === true)
      .map(t => t[0]);
    const formToSend = {
      email: form.email,
      password: form.password,
      phone: form.phone,
      name: form.name + ' ' + form.sobrenome,
      tipo: userTypeFiltered,
    };

    run(register(formToSend));
  };

  return (
    <Container>
      <Box>
        <Link to="/">
          <img src={Logo} alt="Logo AjudaPet" />
        </Link>

        <h2>Cadastro</h2>
        <form>
          <Input
            required
            onChange={onChangeInput}
            value={form.name}
            name="name"
            type="text"
            placeholder="Nome"
          />
          <Input
            required
            onChange={onChangeInput}
            value={form.sobrenome}
            name="sobrenome"
            type="text"
            placeholder="Sobrenome"
          />
          <Input
            required
            maxLength={11}
            onChange={onChangeInput}
            value={form.phone}
            name="phone"
            type="text"
            placeholder="Telefone (Ex: 31912345067)"
          />
          <Input
            required
            onChange={onChangeInput}
            value={form.email}
            name="email"
            type="email"
            placeholder="E-mail"
          />

          <CheckboxContainer>
            {userTypeOptions.map(option => {
              return (
                <>
                  <UserTypeCheckbox
                    required
                    onChange={onChangeCheckboxValue}
                    checked={form.tipo[option.name]}
                    type="checkbox"
                    name={option.name}
                    id={option.name}
                  />
                  <UserTypeLabel
                    isSelected={form.tipo[option]}
                    htmlFor={option.name}
                  >
                    {option.label}
                    {isMobile() ? <br /> : ' - '}
                    <span>{option.description}</span>
                  </UserTypeLabel>
                </>
              );
            })}
          </CheckboxContainer>

          <Input
            style={{ marginTop: '20px' }}
            required
            onChange={onChangeInput}
            value={form.password}
            name="password"
            type="password"
            placeholder="Senha"
          />

          <Button onClick={onTryRegister} bgColor="#3D00C8">
            Cadastrar-se
          </Button>
        </form>
        <div style={{ marginTop: '16px' }}>
          <Link to="/login">
            <Button isLink color="#3D00C8">
              Fazer login
            </Button>
          </Link>
        </div>
        {isRejected && error && <ErrorHolder errors={error ? error : null} />}
        {isPending && <Spin />}
        {isSuccess && <SuccessHolder message={response && response.message} />}
        {isRedirect && <Redirect noThrow to="/login" />}
      </Box>
    </Container>
  );
}

function isMobile() {
  return window.innerWidth <= 718;
}
export default Register;
