import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { useAsync } from 'react-async';

import * as authClient from '../services/auth-client';
import * as helpClient from '../services/help-client';
import { bootstrapAppData } from '../utils/bootstrapAppData';

import FullPageSpinner from '../components/FullPageSpinner';
import UnhandledError from '../components/UnhandledError';

const AuthContext = createContext({});

const AuthProvider = props => {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
  const {
    data = { user: null, institutions: null },
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: bootstrapAppData,
  });

  useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <FullPageSpinner />;
    }
    if (isRejected) {
      return <UnhandledError error={error} />;
    }
  }

  const login = (email, password) =>
    authClient.login(email, password).then(reload);

  const logout = () => authClient.logout().then(reload);

  const register = form => authClient.register(form);

  const registerInstitution = form => authClient.registerInstitution(form);

  const createHelp = form => helpClient.createHelp(form);

  const getHelps = () => helpClient.getHelps();

  const getHelpById = id => helpClient.getHelpById(id);

  const getAddress = cep => helpClient.getAddress(cep);

  const refreshPage = () => Promise.resolve('Refreshed').then(reload);

  const updateUserAvatar = (image, userId) =>
    authClient.updateUserAvatar(image, userId);

  const updateInstitutionAvatar = (image, institutionId) =>
    authClient.updateInstitutionAvatar(image, institutionId);

  const updateUserInfo = form => authClient.updateUserInfo(form);
  const updateInstitutionInfo = form => authClient.updateInstitutionInfo(form);

  const forgotPassword = email => authClient.forgotPassword(email);

  const roleOptions = data &&
    data.user &&
    data.user.data &&
    data.institutions &&
    data.institutions.data && [
      {
        id: '0',
        name: data.user.data.nome,
      },
      ...data.institutions.data.map(institution => ({
        id: institution._id,
        name: institution.nome,
      })),
    ];

  const isLoggedIn = data && data.user !== null;

  const hasInstitutionRegistered =
    data &&
    data.institutions &&
    data.institutions.data &&
    data.institutions.data.length > 0;

  return (
    <AuthContext.Provider
      value={{
        data,
        logout,
        login,
        register,
        isLoggedIn,
        hasInstitutionRegistered,
        createHelp,
        getHelps,
        registerInstitution,
        roleOptions,
        getAddress,
        updateUserAvatar,
        updateUserInfo,
        refreshPage,
        updateInstitutionInfo,
        updateInstitutionAvatar,
        forgotPassword,
        getHelpById,
      }}
      {...props}
    />
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, AuthContext, useAuth };
