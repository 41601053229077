import React, { useState } from 'react';
import { Link } from '@reach/router';

import {
  Container,
  Logo,
  Button,
  AvatarMenu,
  AvatarMenuItems,
  ButtonContainer,
} from './styles';
import logo from '../../assets/images/logo_ajuda_pet.png';
import { useAuth } from '../../context/auth';
import NoAvatar from '../../assets/images/noAvatar.png';

function isMobile() {
  return window.innerWidth <= 718;
}

export default function Header({ scrollToHelpBlog }) {
  const { logout, isLoggedIn, data, hasInstitutionRegistered } = useAuth();
  const [menuOpened, setMenuOpened] = useState(false);

  return isMobile() ? (
    <Container>
      <Link to="/">
        <Logo src={logo} alt="Ajuda pet Logotipo" />
      </Link>
      <span>
        {isLoggedIn ? (
          <AvatarMenu onClick={() => setMenuOpened(!menuOpened)}>
            <AvatarMenuItems isOpened={menuOpened}>
              <Link to="/perfil">
                <li>Perfil</li>
              </Link>
              {hasInstitutionRegistered && (
                <Link to="/perfil-instituicao">
                  <li>Minha instituição</li>
                </Link>
              )}

              <li onClick={logout}>Sair</li>
            </AvatarMenuItems>
            <img
              src={
                data && data.user && data.user.data && data.user.data.avatar
                  ? data.user.data.avatar
                  : NoAvatar
              }
              alt="sem avatar"
            />
          </AvatarMenu>
        ) : (
          <Link to="/login">
            <Button>Entrar</Button>
          </Link>
        )}
      </span>
    </Container>
  ) : (
    <Container>
      <Link to="/">
        <Logo src={logo} alt="Ajuda pet Logotipo" />
      </Link>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <ButtonContainer
          target="_blank"
          href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=EVJGJVTSZYXA2&source=url"
        >
          <Button>Doe</Button>
        </ButtonContainer>
        {isLoggedIn ? (
          <AvatarMenu onClick={() => setMenuOpened(!menuOpened)}>
            <AvatarMenuItems isOpened={menuOpened}>
              <Link to="/perfil">
                <li>Perfil</li>
              </Link>
              {hasInstitutionRegistered && (
                <Link to="/perfil-instituicao">
                  <li>Minha instituição</li>
                </Link>
              )}
              <li onClick={logout}>Sair</li>
            </AvatarMenuItems>
            <img
              src={
                data && data.user && data.user.data && data.user.data.avatar
                  ? data.user.data.avatar
                  : NoAvatar
              }
              alt="sem avatar"
            />
          </AvatarMenu>
        ) : (
          <Link to="/login">
            <Button isOutline>Entrar</Button>
          </Link>
        )}
      </span>
    </Container>
  );
}
