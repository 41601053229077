import styled from 'styled-components';

import { px2vw } from '../../utils/px2vw';
import LightenDarkenColor from '../../utils/LightenDarkenColor';

export const Container = styled.div`
  margin-top: ${px2vw(24)};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #050050;
  padding: ${px2vw(44)} ${px2vw(240)} 0 ${px2vw(240)};
  padding-bottom: 70px;
  margin-top: 150px;

  @media only screen and (max-width: 600px) {
    padding: 0;
    align-items: center;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 80%;
  }
`;

export const ImageContainer = styled.img`
  width: ${px2vw(116)};
  height: ${px2vw(116)};
  margin-right: ${px2vw(40)};

  @media only screen and (max-width: 600px) {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-top: 15px;
  }
`;

export const Title = styled.h2`
  width: 100%;
  text-align: ${props => props.textAlign || 'center'};
  color: ${props => props.color || '#fff'};
  font-size: ${props => props.fontSize || '2rem'};
  font-weight: ${props => props.fontWeight || 'normal'};
  margin-top: ${props => (props.marginTop ? px2vw(props.marginTop) : 'unset')};

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    margin-top: 13px;
  }
`;

export const PostsTitle = styled(Title)`
  @media only screen and (max-width: 600px) {
    font-size: 25px;
    margin-top: 13px;
    text-align: center;
  }
`;

export const PostsContainer = styled.div`
  width: 100%;
  margin-top: ${px2vw(30)};
  padding: ${px2vw(28)} ${px2vw(35)};
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 600px) {
    border-radius: 0;
    box-shadow: none;
    margin-top: 50px;
  }
`;

export const HeaderPostsContainer = styled.div`
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${px2vw(24)};
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
`;

export const SearchInput = styled.input`
  width: ${props => px2vw(props.width)};
  margin-right: ${px2vw(16)};
  height: ${px2vw(42)};
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  padding-left: 16px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: ${px2vw(20)};
  color: #4f4f4f;

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 15px;
    height: 40px;
    line-height: normal;
    padding-left: 15px;
  }
`;

export const Post = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin-top: 24px;
  padding: ${px2vw(16)} ${px2vw(16)};
  border-left-width: 6px;
  border-left-color: #4bd1a0;
  border-left-style: solid;

  @media only screen and (max-width: 600px) {
    height: fit-content;
    padding: 15px;
  }
`;

export const ShareIconContainer = styled.div`
  position: absolute;
  cursor: pointer;
  color: #050050;
  bottom: 20px;
  right: 20px;

  &:hover {
    color: ${LightenDarkenColor('#050050', -30)};
  }

  @media only screen and (max-width: 600px) {
    top: 15px;
    bottom: auto;
  }
`;

export const PostHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: space-between;

    p {
      font-size: 15px;
    }
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-family: Circular Std;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 18px;
    color: #333333;
  }

  img {
    width: ${px2vw(75)};
    height: ${px2vw(75)};
    border-radius: 50%;
    margin-right: ${px2vw(20)};

    @media only screen and (max-width: 600px) {
      width: 80px;
      height: 80px;
    }
  }

  div {
    h2 {
      font-family: Circular Std;
      font-style: normal;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: ${px2vw(30)};
      color: #050050;

      @media only screen and (max-width: 600px) {
        font-size: 18px;
        line-height: 20px;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: ${px2vw(14)};
      color: #828282;
      margin-top: 5px;

      @media only screen and (max-width: 600px) {
        font-size: 13px;
        line-height: 12px;
      }
    }
  }
`;

export const HelpTypesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
  }
`;

export const NeedsContainer = styled.div`
  width: fit-content;
  padding: 20px;

  @media only screen and (max-width: 600px) {
    padding: 10px;
  }

  p {
    margin-left: 10px;
    @media only screen and (max-width: 600px) {
      margin-left: 3px;
      font-size: 13px;
    }
  }
`;

export const Badge = styled.div`
  background-color: ${props => props.bgColor || '#C19E69'};
  color: ${props => props.color || '#fff'};
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: ${px2vw(18)};
  text-align: center;
  border-radius: 10px;
  padding: 2px 10px;
  margin-top: 5px;
  margin-left: 10px;

  @media only screen and (max-width: 600px) {
    padding-top: 6px;
    font-size: 13px;
    height: 17px;
    margin-left: 3px;
  }
`;

export const Message = styled.p`
  margin-top: ${px2vw(28)};
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: ${px2vw(23)};
  color: #333333;

  @media only screen and (max-width: 600px) {
    font-size: 17px;
    line-height: normal;
    margin-left: 13px;
  }
`;

export const Icon = styled.span`
  margin-right: 5px;
  .icon {
    stroke: #00a57a;
  }
`;

export const WhatsAppButton = styled.a`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: 15px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  background-color: #00e676;
  width: 180px;
  text-decoration: none;
  @media only screen and (max-width: 600px) {
    line-height: normal;
    margin-left: 13px;
  }
`;
