import styled from 'styled-components';
import { px2vw } from '../../utils/px2vw';
import { StyledButton } from '../../components/Button/styles';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${px2vw(24)} ${px2vw(64)} 0 ${px2vw(64)};
  flex-direction: column;
  margin-top: 20px;
`;

export const InfoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${px2vw(376)};
  height: ${px2vw(84)};
  background-color: #3d00c8;
  color: #fff;
  font-family: 'Circular Std';
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;

  @media only screen and (max-width: 600px) {
    width: 170px;
    height: 40px;
    font-size: 30px;
  }
`;

export const Text = styled.p`
  margin-top: ${props => (props.marginTop ? px2vw(props.marginTop) : 0)};
  font-family: 'Circular Std';
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: ${props => props.fontWeight || 'normal'};
  color: ${props => props.color || '#050050'};
  width: 100%;
  text-align: ${props => props.textAlign || 'unset'};

  @media only screen and (max-width: 600px) {
    font-size: ${props => (props.as ? '18px' : '17px')};
    margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : 0)};
  }
`;

export const WrapContent = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${px2vw(52)};
  justify-content: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${px2vw(44)} ${px2vw(50)};
  width: ${px2vw(424)};
  border-radius: ${px2vw(16)};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.32);
  margin: 0 ${px2vw(50)};

  @media only screen and (max-width: 600px) {
    width: 80%;
    text-align: center;
    justify-content: space-between;
    margin: 20px;
    padding: 20px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: ${px2vw(64)};
  margin-bottom: ${px2vw(46)};
  width: ${px2vw(168)};
  height: ${px2vw(168)};

  > img {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 60%;
    text-align: center;
    margin-top: 30px;
    height: 150px;
  }
`;

export const Button = styled(StyledButton)`
  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 17px;
    margin-top: 15px;
  }
`;

export const Title = styled.h1`
  width: 100%;
  font-family: 'Circular Std';
  font-size: 2.875rem;
  text-align: center;
  color: #3d00c8;
  span {
    color: #00ee96;
  }

  @media only screen and (max-width: 600px) {
    font-size: 30px;
  }
`;
