import React from 'react';
import 'dayjs/locale/pt-br';
import { Router } from '@reach/router';

import GlobalStyle from './styles/global';
import Login from './pages/Login';
import AskForHelp from './pages/AskForHelp';
import Main from './pages/Main';
import Register from './pages/Register';

import { AuthProvider } from './context/auth';
import RegisterInstitution from './pages/RegisterInstitution';
import Profile from './pages/Profile';
import InstitutionProfile from './pages/InstitutionProfile';
import ForgotPassword from './pages/ForgotPassword';
import SingleHelp from './pages/SingleHelp';

function App() {
  return (
    <>
      <GlobalStyle />
      <AuthProvider>
        <Router>
          <Main path="/" />
          <Login path="/login" />
          <AskForHelp path="/pedir-ajuda" />
          <Register path="/cadastro" />
          <RegisterInstitution path="/cadastro-instituicao" />
          <Profile path="/perfil" />
          <InstitutionProfile path="/perfil-instituicao" />
          <ForgotPassword path="/esqueci-senha" />
          <SingleHelp path="/ajuda/:helpId" />
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
