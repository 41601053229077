import React from 'react';
import { Container } from './styles';
import { Spin } from './styles';

function FullPageSpinner() {
  return (
    <Container>
      <Spin />
    </Container>
  );
}

export default FullPageSpinner;
